import React from 'react'
import './header.scss';
import Logo from '../assets/logo/logo.webp';
export default function Header() {
  return (
    <div>
      <header>
        <div className='container'>
          <img src={Logo} alt='Logo'/>
        </div>
      </header>
    </div>
  )
}
