import React, { useState } from "react";
import "./home.scss";
import VectoreImage from "./assets/imges/vectore.svg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Markdown from "react-markdown";
import ClipLoader from "react-spinners/ClipLoader";
import "./App.css";
import "./home.scss";
import Header from "./header/header";
function App() {
  const [toogle, setToogle] = useState(false);
  const [inputData, setInputData] = useState({ description: "", keywords: [] });
  const [currentKeyword, setCurrentKeyword] = useState("");
  const [generatedData, setgeneratedData] = useState("");
  const [disable, setDisable] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const color = "#ffffff";

  const handleDescriptionChange = (e) => {
    setErrors((prevErrors) => ({ ...prevErrors, description: "", keywords: "" }));
    setInputData((prevData) => ({ ...prevData, description: e.target.value }));
  };

  const handleKeywordChange = (e) => {
    setErrors((prevErrors) => ({ ...prevErrors, description: "", keywords: "", keywordsData: "" }));
    setCurrentKeyword(e.target.value);
  };

  const handleKeyPress = (e) => {
    const clickedClass = e.currentTarget.className;
    setErrors((prevErrors) => ({ ...prevErrors, description: "", keywords: "" }));
    if ((e.key === "Enter" && currentKeyword?.trim() !== "") || clickedClass == "fa-solid fa-plus") {
      if (!inputData?.keywords?.includes(currentKeyword)) {
        setInputData((prevData) => ({
          ...prevData,
          keywords: [...prevData.keywords, currentKeyword],
        }));
        setCurrentKeyword("");
      } else {
        setErrors({ keywordsData: "Please Enter unique keyword" });
      }
    }
  };

  const removeKeywords = (data) => {
    setInputData((prevData) => ({
      ...prevData,
      keywords: prevData.keywords.filter((item) => item !== data),
    }));
  };

  const generateNow = async () => {
    setDisable(true);
    const validation = validationFn(inputData);
    let url = "https://api.writer.rejoicehub.com/generate-content";
    if (validation) {
      setLoading(true);
      try {
        const response = await axios.post(
          url,
          { description: inputData.description, keywords: inputData.keywords },
          { headers: { "ngrok-skip-browser-warning": true } }
        );
        setgeneratedData(response?.data?.generated_content);
        setToogle(true);
        toast.success("Content generated successfully!");
        setLoading(false);
      } catch (error) {
        toast.error("Please try again.");
      } finally {
        setLoading(false);
        setDisable(false);
      }
    } else {
      setDisable(false);
    }
  };

  const validationFn = (inputData) => {
    let error = {};
    let inputVal = true;

    if (!inputData?.description || inputData?.description.trim() === "") {
      inputVal = false;
      error.description = "Description is required";
    }

    if (!inputData?.keywords || inputData.keywords.length === 0) {
      inputVal = false;
      error.keywords = "Keyword is required";
    }

    setErrors(error);
    return inputVal;
  };

  const clearInput = () => {
    setToogle(false);
    setInputData({ description: "", keywords: [] });
    setgeneratedData("");
  };
  const copyContent = async () => {
    await navigator.clipboard.writeText(generatedData);
  };

  return (
    <div>
      <Header />
      <div className="home-page-alignment">
        <ToastContainer />
        <div className="w-fill">
          <div className="container">
            <div className={loading || toogle ? "grid grid-change" : "grid"}>
              <div className="grid-items">
                <div className="input">
                  <div className="input-label">
                    <label>Description</label>
                    <div>{errors?.description && <span style={{ color: "red", fontSize: "13px" }}>{errors?.description}</span>}</div>
                  </div>
                  <textarea
                    placeholder="Description"
                    name="discription"
                    value={inputData.description.charAt(0).toUpperCase() + inputData.description.slice(1)}
                    onChange={handleDescriptionChange}
                  ></textarea>
                </div>
                <div>
                  <div className="input">
                    <div className="input-label">
                      <label>Keywords</label>
                      <div>
                        {errors?.keywords && <span style={{ color: "red", fontSize: "13px" }}>{errors?.keywords}</span>}
                        {errors.keywordsData && <span style={{ color: "red", fontSize: "13px" }}>{errors?.keywordsData}</span>}
                      </div>
                    </div>
                    <div className="keywords-relative">
                      <input
                        type="text"
                        placeholder="Keyword"
                        name="keyword"
                        value={currentKeyword.charAt(0).toUpperCase() + currentKeyword.slice(1)}
                        onChange={handleKeywordChange}
                        onKeyDown={(e) => handleKeyPress(e)}
                      />
                      <div className="plus-icon">
                        <i class="fa-solid fa-plus" onClick={(e) => handleKeyPress(e)}></i>
                      </div>
                    </div>
                  </div>
                  <div className="keyword-link">
                    {inputData.keywords.map((data) => {
                      return (
                        <div>
                          <button onClick={() => removeKeywords(data)}>
                            {data.charAt(0).toUpperCase() + data.slice(1)}
                            <i class="fa-solid fa-xmark"></i>
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="two-btn-alignment">
                  <button disabled={disable} className="button-design" onClick={generateNow}>
                    Generate Now
                    {!loading ? "" : <ClipLoader color={color} loading={loading} size={20} aria-label="Loading Spinner" data-testid="loader" />}
                  </button>
                  <div className="reset" onClick={clearInput}>
                    <i class="fa-solid fa-rotate-right"></i>
                  </div>
                </div>
              </div>
              <div className="grid-items">
                <div className="card">
                  {!toogle ? (
                    <div className="h-full-alignment">
                      <div>
                        <div className="img-center">
                          <img src={VectoreImage} alt="VectoreImage" />
                        </div>
                        <h4>Generate Content with easy steps, we help you with AI content generator via simple steps</h4>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="copy-icon">
                        <i class="fa-regular fa-copy" onClick={copyContent}></i>
                      </div>
                      <div className="markdown-content">
                        <Markdown>{generatedData}</Markdown>
                      </div>
                    </div>
                  )}
                </div>
                {loading ? (
                  <div className="loader-design-center">
                    <div>
                      <p>Typing...</p>
                      <div class="progress"></div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {/* <div className="loader-design-center">
              <div class="progress"></div>
              </div> */}
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
}

export default App;
